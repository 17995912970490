import { reactive } from 'vue'
import {
  SessionRegistryStore,
  SessionRegistryState,
  SessionRegistryActions
} from './SessionRegistryStore.interfaces'
import { SessionListItemViewModel } from '@/components/SessionListItem'
import { Event as ClassroomEvent, WithId } from '@/types/api'
import { useClassroomStudentService } from '@/components/ClassroomStudentService'
import {
  createDateFormatter,
  checkIsValidEventTiming
} from './SessionRegistryStore.utils'
import locales from './SessionRegistryStore.locales.en.json'
import { RetryWithBackoff } from '@/utils/retry-call'
import { formatTimeRange } from '@/utils/date'

export function createSessionRegistryStore(): SessionRegistryStore {
  const classroomStudentService = useClassroomStudentService()

  const sessionState: SessionRegistryState = reactive({
    sessions: [],
    webinars: [],
    attendeeIsRegistered: false,
    isLoading: false,
    isReady: false,
    isLSAT: false,
    isEBar: false,
    isRegistering: false,
    coachName: ''
  })

  const sessionActions: SessionRegistryActions = {
    loadSessions,
    registerToSession,
    selectSession,
    unSelectSession
  }

  async function loadSessions() {
    try {
      sessionState.isLoading = true

      const productId = classroomStudentService.fetchProductId()
      sessionState.isLSAT = productId === 'PowerScore_LSAT_Premium'
      sessionState.isEBar = ['EXTENDED_BAR_6_MONTH', 'EXTENDED_BAR_10_MONTH'].includes(productId)

      const [events, webinars] = await Promise.all([
        classroomStudentService.fetchEvents(sessionState.isLSAT),
        sessionState.isEBar ? classroomStudentService.fetchWebinars() : []
      ])

      const attendee = classroomStudentService.fetchAttendee()
      const registeredEventIds = new Set(
        events.filter(event => event.attendees.some(att => att.toUpperCase() === attendee.toUpperCase())).map(event => event.id)
      )
      
      sessionState.sessions = events.filter(event => event.eventType != 'Webinar').map((event) =>
        createSessionListItemViewModel(
          event,
          registeredEventIds
        )
      )
      sessionState.webinars = webinars.map((event) =>
        createSessionListItemViewModel(
          event,
          registeredEventIds
        )
      )
      sessionState.isReady = true
      sessionState.attendeeIsRegistered = !!registeredEventIds.size
    } catch (error) {
      sessionState.error =
        error instanceof Error
          ? error.message
          : locales.message_unexpected_error
    } finally {
      sessionState.isLoading = false
    }
  }

  function createSessionListItemViewModel(
    event: ClassroomEvent & WithId,
    registeredEventIds: Set<string>
  ): SessionListItemViewModel {
    
    if(!event.tags)
      event.tags = []

    if (checkIsValidEventTiming(event.meetingOccurrences)) {
      const formatter = createDateFormatter(event.meetingOccurrences)
      const dateRange = formatter.formatDateRange()
      const isRegistered = registeredEventIds.has(event.id)

      return reactive({
        attendees: event.attendees,
        sessionId: event.id,
        coachId: event.coachId,
        showRegistered: isRegistered,
        startDate: event.startDate ?? '',
        startDateTime: event.startDate + 'T' + event.startTime,
        startEndDate: dateRange,
        localStartDateTime: formatter.startLocalDateTime.toJSDate(),
        localEndDateTime: formatter.endLocalDateTime.toJSDate(),
        localStartEndTime: formatTimeRange(event),
        isFull: event.attendeesUnfilteredCount >= event.maxAttendees,
        eTag: event.eTag,
        tags: event.tags,
        isLSAT: sessionState.isLSAT,
        event: event
      })
    }

    return reactive({
      attendees: event.attendees,
      sessionId: event.id,
      coachId: event.coachId,
      showRegistered: false,
      startDate: event.startDate ?? '',
      startDateTime: event.startDate && event.startTime ? event.startDate + 'T' + event.startTime : '',
      startEndDate: locales.message_invalid_date_range,
      localStartDateTime: event.startDate && event.startTime ? new Date(event.startDate + 'T' + event.startTime) : new Date(),
      localEndDateTime: event.endDate && event.endTime ? new Date(event.endDate + 'T' + event.endTime) : new Date(),
      localStartEndTime: locales.message_invalid_time_range,
      isFull: event.attendeesUnfilteredCount >= event.maxAttendees,
      eTag: event.eTag,
      tags: event.tags,
      isLSAT: sessionState.isLSAT,
      event: event
    })
  }

  async function registerToSession() {
    sessionState.error = ''
    if (sessionState.attendeeIsRegistered && !sessionState.isLSAT) {
      sessionState.error = locales.message_attendee_already_registered_error
      return
    }
    if (!sessionState.selectedSession) {
      sessionState.error = locales.message_missing_selected_session_error
      return
    }
    try {
      sessionState.isRegistering = true

      const eventId = sessionState.selectedSession.sessionId

      const getETagFromEvent = async () => {
        await loadSessions()
        const selectedEvent = sessionState.sessions.find(
          (x) => x.sessionId === eventId
        )
        if (selectedEvent) {
          return selectedEvent.eTag
        } else {
          throw 'Event not found when retry'
        }
      }

      const getEtagFactory = () => {
        let useFirstEtag = true
        return async () => {
          if (useFirstEtag) {
            useFirstEtag = false
            return sessionState.selectedSession!.eTag
          } else {
            return await RetryWithBackoff(
              { MaximumRetries: 5 },
              getETagFromEvent
            )
          }
        }
      }

      await classroomStudentService.addAttendeeToEvent(
        eventId,
        getEtagFactory()
      )
    } catch (error) {
      sessionState.error =
        error instanceof Error
          ? error.message
          : locales.message_unexpected_error
    } finally {
      sessionState.isRegistering = false
    }
  }

  async function selectSession(session: SessionListItemViewModel) {
    sessionState.selectedSession = session
    const coach = await classroomStudentService.fetchCoachById(session.coachId)
    sessionState.coachName = coach.name
  }

  function unSelectSession() {
    sessionState.selectedSession = undefined
  }

  return {
    sessionState,
    sessionActions
  }
}
